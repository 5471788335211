import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../../../modules/Auth/_redux/authRedux';
// import config from '../../../../config';

function AddProductMain(props) {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [fileimg, setFileImg] = useState();
    const [category, setCategory] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [unit, setUnit] = useState();
    const [price, setPrice] = useState();
    const [vat, setVat] = useState();
    const [quantity, setQuantity] = useState();

    // const [base64img ,setBase64Img]= useState();

    const onFileChange = event => {
        // Update the state
        setFileImg(event.target.files[0] );
      };
    const  getBase64=(event)=> {
        const File =(event.target.files[0] )
        const reader = new FileReader();
        reader.readAsDataURL(File);
        reader.onload = () => {
            setFileImg(reader.result)
        };
    }
    const handleCreate =()=>{
        props.handleCreate(name,description,unit,price,vat,quantity,fileimg,category)
    }
    const onPriceChange=(e) =>{
        console.log("value",e.target.value)
            setPrice(e.target.value, )
            setVat(parseFloat(e.target.value*0.07).toFixed(2))
    }
    return (
        <div className='setting-change-password-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row justify-content-center align-content-center min-h-500px'>
                    <div className='col-lg-8 col-12 p-md-20 pt-10 bg-white'>
                        <div className='row justify-content-center'>
                            <div className='col-12 text-center p-0'>
                                <h6 className='text-dark font-weight-bold mb-10'>เพิ่มสินค้า</h6>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>หมวดหมู่</label>
                            <div className='col-10'>
                                <select className='form-control form-control-lg rounded-lg'
                                value={category}
                                onChange={(e)=>setCategory(e.target.value)}//setCategory(value.key)
                                placeholder='เลือกหมวดหมู่สินค้า'
                                >
                                    <option value='' disabled selected>เลือกหมวดหมู่สินค้า</option>
                                    {props.categoryFind.map((item) => {
                                        
                                            return(
                                            <option  key={item.id} value={item.title}>{item.title}</option>
                                            )
                                    })}
                                </select>
                               
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>ชื่อสินค้า</label>
                            <div className='col-10'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>รายละเอียด</label>
                            <div className='col-10'>
                                <textarea
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>หน่วยสินค้า</label>
                            <div className='col-10'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>ราคา</label>
                            <div className='col-10'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={price}
                                    onChange={onPriceChange}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>vat7%</label>
                            <div className='col-10'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={vat}
                                    onChange={(e) => setVat(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>จำนวน</label>
                            <div className='col-10'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-2 text-lg-right text-left'>ภาพสินค้า</label>
                            <div className='col-10'>
                                <input type="file" 
                                onChange={getBase64} 
                                />
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <button type='button' className='btn btn-primary rounded-lg' 
                            onClick={handleCreate}>
                                ยืนยัน
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(AddProductMain));
