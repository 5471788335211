import React, { useEffect } from 'react';

import Select, { components } from 'react-select';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import { Dropdown } from 'react-bootstrap';

import $ from 'jquery';

const controlStyles = {
    borderRadius: '0.85rem',
    // padding: '5px',
    // background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        '&:hover': {
            borderColor: '#3699FF'
        },
        backgroundColor: state.isDisabled ? '#F3F6F9' : 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        borderColor: state.isFocused ? '#3699FF' : '#E4E6EF',
        borderStyle: 'solid',
        borderWidth: '1px'
    })
};

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31
};

const statusList = [
    {
        label: 'รอการชำระเงิน',
        value: 'pending'
    },
    {
        label: 'ยกเลิก',
        value: 'cancel'
    },
    {
        label: 'หมดอายุ',
        value: 'expired'
    },
    {
        label: 'ชำระแล้ว',
        value: 'paid'
    },
    {
        label: 'ยืนยันสินค้า',
        value: 'completed'
    }
];

export function OrderList(props) {
    const selectStatus = (item) => {
        if (item) {
            props.setStatus(item);
        } else {
            props.setStatus('');
        }
    };

    const emptyDate = () => {
        props.setDate({
            from: null,
            to: null
        });
    };

    const emptyPDate = () => {
        props.setPDate({
            from: null,
            to: null
        });
    };

    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.orderlist).length > 0) {
                    if (props.orderlist.responsecode === 0) {
                        $('#order-list-table').DataTable({
                            data: props.orderlist.info,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            order: [ [ 2, 'asc' ] ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            columns: [
                                {
                                    title: 'ดูรายละเอียด',
                                    className: 'dt-body-center',
                                    data: null,
                                    render: function(data, type, row) {
                                        return `<button
                                                type="button"
                                                class="btn btn-icon btn-primary rounded-lg view-info"
                                                data-orderid="${data.id}"
                                            >
                                                <i class="fa fa-search"></i>
                                            </button>`;
                                    }
                                },
                                {
                                    title: 'หมายเลขคำสั่งซื้อ',
                                    // data: "name",
                                    data: function(value) {
                                        if (value.id !== null && value.id !== '') {
                                            return value.id;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่สั่งซื้อ',
                                    data: function(value) {
                                        if (value.create_date !== null && value.create_date !== '') {
                                            return value.create_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ช่องทางชำระเงิน',
                                    className: 'dt-body-center',
                                    data: function(value) {
                                        if (value.payment_id !== null && value.payment_id !== '') {
                                            if (value.payment_id === '1') {
                                                return '<span class="label label-danger label-pill label-inline mx-auto my-auto">กระเป๋าซิมปันสุข</span>';
                                            } else {
                                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">คิวอาร์โค้ด</span>';
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่ชำระเงิน',
                                    data: function(value) {
                                        if (value.paid_date !== null && value.paid_date !== '') {
                                            return value.paid_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'สถานะคำสั่งซื้อ',
                                    className: 'dt-body-center',
                                    data: function(value) {
                                        if (value.status !== null && value.status !== '') {
                                            if (value.status === 'pending') {
                                                return '<span class="label label-light-dark label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                                            } else if (value.status === 'paid') {
                                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">ชำระแล้ว</span>';
                                            } else if (value.status === 'cancel') {
                                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ยกเลิก</span>';
                                            } else if (value.status === 'expired') {
                                                return '<span class="label label-danger label-pill label-inline mx-auto my-auto">หมดอายุ</span>';
                                            } else if (value.status === 'completed') {
                                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ยืนยันสินค้าแล้ว</span>';
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ราคา',
                                    data: function(value) {
                                        if (value.total_gross_price !== null && value.total_gross_price !== '') {
                                            return value.total_gross_price;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.orderlist ]
    );

    const renderCustomInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    props.date.from && props.date.to ? (
                        props.date.from.year +
                        '/' +
                        ('0' + props.date.from.month).slice(-2) +
                        '/' +
                        ('0' + props.date.from.day).slice(-2) +
                        ' - ' +
                        props.date.to.year +
                        '/' +
                        ('0' + props.date.to.month).slice(-2) +
                        '/' +
                        ('0' + props.date.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${props.date.from && props.date.to
                    ? ' rounded-right-0'
                    : ''}`}
            />
            {props.date.from &&
            props.date.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    const renderCustomInputPDate = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    props.pdate.from && props.pdate.to ? (
                        props.pdate.from.year +
                        '/' +
                        ('0' + props.pdate.from.month).slice(-2) +
                        '/' +
                        ('0' + props.pdate.from.day).slice(-2) +
                        ' - ' +
                        props.pdate.to.year +
                        '/' +
                        ('0' + props.pdate.to.month).slice(-2) +
                        '/' +
                        ('0' + props.pdate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${props.pdate.from && props.pdate.to
                    ? ' rounded-right-0'
                    : ''}`}
            />
            {props.pdate.from &&
            props.pdate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyPDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    return (
        <div className='row main-page'>
            <div className='col-12 row m-0 mb-5 justify-content-end'>
                <button type='button' className='btn btn-primary rounded-lg' onClick={props.handleShow}>
                    <i className='fas fa-file-upload' /> อัพโหลด
                </button>
            </div>
            <div className='col-12'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        <div className='row form-group'>
                            <div className='col-12 col-md-3 form-group'>
                                <div className='form-group mb-0'>
                                    <label>หมายเลขคำสั่งซื้อ</label>
                                    <input
                                        type='text'
                                        className='form-control rounded-lg'
                                        value={props.oid}
                                        onChange={(e) => props.setOid(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                props.btnSearch();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3 form-group'>
                                <div className='form-group mb-0'>
                                    <label>สถานะคำสั่งซื้อ</label>
                                    <Select
                                        components={{
                                            Control: ControlComponent
                                        }}
                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                        value={props.status}
                                        options={statusList}
                                        placeholder='เลือก'
                                        id='status'
                                        name='status'
                                        onChange={selectStatus}
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3 form-group'>
                                <div className='form-group mb-0'>
                                    <label>วันที่สั่งซื้อ</label>
                                    <DatePicker
                                        value={props.date}
                                        onChange={props.setDate}
                                        renderInput={renderCustomInput}
                                        minimumDate={minimumDate}
                                        maximumDate={utils().getToday()}
                                        locale={myCustomLocale}
                                        wrapperClassName='col-12 p-0'
                                        calendarClassName='responsive-calendar'
                                        calendarPopperPosition='bottom'
                                        shouldHighlightWeekends
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3 form-group'>
                                <div className='form-group mb-0'>
                                    <label>วันที่ชำระเงิน</label>
                                    <DatePicker
                                        value={props.pdate}
                                        onChange={props.setPDate}
                                        renderInput={renderCustomInputPDate}
                                        minimumDate={minimumDate}
                                        maximumDate={utils().getToday()}
                                        locale={myCustomLocale}
                                        wrapperClassName='col-12 p-0'
                                        calendarClassName='responsive-calendar'
                                        calendarPopperPosition='bottom'
                                        shouldHighlightWeekends
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-right'>
                                <button
                                    type='button'
                                    onClick={props.btnSearch}
                                    className='btn btn-sm btn-primary rounded-lg'
                                >
                                    <i className='far fa-dot-circle' /> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!props.init && (
                <div className='col-12 my-5'>
                    <div className='card rounded-lg'>
                        <div className='card-body'>
                            {Object.keys(props.orderlist).length <= 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {props.orderlist.responsecode !== 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>
                                                    ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                    กรุณาลองใหม่อีกครั้ง
                                                </h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='row col-12 justify-content-end m-0 mb-5 p-0'>
                                                <Dropdown className='rounded-lg'>
                                                    <Dropdown.Toggle
                                                        className='rounded-lg'
                                                        id='dropdown-autoclose-true'
                                                    >
                                                        คำสั่งต่างๆ
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => props.btnDownload(false)}>
                                                            ดาวน์โหลดรายการจัดส่ง
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => props.btnDownload(true)}>
                                                            ดาวน์โหลดรายการจัดส่ง (Kerry Format)
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item href="#">ยกเลิกรายการ</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className='col-12 p-0'>
                                                <table
                                                    id='order-list-table'
                                                    className='table table-striped table-bordered'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
