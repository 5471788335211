import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import Moment from 'moment';

import 'moment/locale/th';

import initaddress from '../../../../address';

import config from '../../../../config';

export function MemberDetail(props) {
    const [ loading, setLoading ] = useState(false);
    const [ address, setAddress ] = useState('');
    const [ historys, setHistorys ] = useState({});

    const initindex = 3;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(3);

    const btnPrevious = (e) => {
        e.preventDefault();
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = (e) => {
        e.preventDefault();
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    useEffect(() => {
        let addresslist = [];
        if (props.memberdetail.info.address.address1 !== '') {
            addresslist.push(props.memberdetail.info.address.address1);
        }
        if (props.memberdetail.info.address.address2 !== '') {
            addresslist.push(props.memberdetail.info.address.address2);
        }
        if (props.memberdetail.info.address.subdistrict !== '') {
            for (const thissubdistrict in initaddress.subdistrict) {
                if (
                    initaddress.subdistrict[thissubdistrict]['value'] ===
                    props.memberdetail.info.address.subdistrict.toString()
                ) {
                    addresslist.push(initaddress.subdistrict[thissubdistrict]['label']);
                    break;
                }
            }
        }
        if (props.memberdetail.info.address.district !== '') {
            for (const thisdistrict in initaddress.district) {
                if (
                    initaddress.district[thisdistrict]['value'] === props.memberdetail.info.address.district.toString()
                ) {
                    addresslist.push(initaddress.district[thisdistrict]['label']);
                    break;
                }
            }
        }
        if (props.memberdetail.info.address.province !== '') {
            for (const thisprovince in initaddress.province) {
                if (
                    initaddress.province[thisprovince]['value'] === props.memberdetail.info.address.province.toString()
                ) {
                    addresslist.push(initaddress.province[thisprovince]['label']);
                    break;
                }
            }
        }
        if (props.memberdetail.info.address.postalcode !== '') {
            addresslist.push(props.memberdetail.info.address.postalcode);
        }
        setAddress(addresslist.join(' '));
        getHistory();
    }, []);

    const getHistory = () => {
        setLoading(true);
        fetch(config.url + 'admin/order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.user.token,
                filters: {
                    user_id: props.memberdetail.info.id.toString()
                }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setHistorys(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    return (
        <div className='row detail-page'>
            <div className='col-12 mb-5'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        <div className='row m-0 justify-content-start'>
                            <div className='col-12 p-0'>
                                <div className='mr-auto'>
                                    <button
                                        type='button'
                                        className='btn btn btn-link-primary rounded-lg'
                                        onClick={() => props.setPage('main')}
                                    >
                                        <i className='fas fa-angle-double-left icon-2x py-3' />
                                        ย้อนกลับ
                                    </button>
                                </div>
                            </div>
                            <div className='col-12 p-0'>
                                <div className='d-inline-block mb-5'>
                                    <span>
                                        <div>
                                            <span className='d-inline-block'>ข้อมูลสมาชิก : Information</span>
                                        </div>
                                        <div>
                                            <h1>
                                                {props.memberdetail.info.first_name === '' ? (
                                                    '{ชื่อ}'
                                                ) : (
                                                    props.memberdetail.info.first_name
                                                )}{' '}
                                                {props.memberdetail.info.last_name === '' ? (
                                                    '{นามสกุล}'
                                                ) : (
                                                    props.memberdetail.info.last_name
                                                )}
                                            </h1>
                                        </div>
                                    </span>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-12 col-md-6 p-0 mb-7 mb-md-0'>
                                        <h2 className='d-inline-block mb-5'>รายละเอียดสมาชิก</h2>
                                        <div className='row justify-content-between m-0'>
                                            <div className='col-12 col-md-6 form-group p-0'>
                                                <h6 className='d-block m-0'>
                                                    เบอร์ติดต่อ : {props.memberdetail.info.contact_number}{' '}
                                                </h6>
                                            </div>
                                            <div className='col-12 form-group p-0'>
                                                <h6 className='d-block m-0'> ที่อยู่จัดส่งหลัก : {address} </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <h2 className='d-inline-block mb-5'>ประวัติรายการสั่งซื้อ</h2>
                                        {loading ? (
                                            <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                                <div className='text-center col-12 p-0'>
                                                    <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                                                    <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {Object.keys(historys).length <= 0 ? (
                                                    <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                                        <div className='text-center col-12 p-0'>
                                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                            <h1 className='col mt-5'>
                                                                มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                            </h1>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {historys.responsecode !== 0 ? (
                                                            <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                                                <div className='text-center col-12 p-0'>
                                                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                    <h1 className='col mt-5'>
                                                                        มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='historys-content'>
                                                                <ul className='xsearch-items'>
                                                                    {historys.info
                                                                        .slice(startindex, endindex)
                                                                        .map((object, index, arr) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <li className='search-item'>
                                                                                        <div className='search-item-content mx-7 pb-0'>
                                                                                            <h3 className='search-item-caption'>
                                                                                                <div className='text-primary d-inline-block'>
                                                                                                    # {object.id}
                                                                                                </div>
                                                                                            </h3>
                                                                                            <div className='search-item-meta mb-5'>
                                                                                                <ul className='list-inline'>
                                                                                                    <li className='time d-block'>
                                                                                                        <h6>
                                                                                                            วันที่:{' '}
                                                                                                            {Moment(new Date(object.create_date)).add(543, 'year').locale('th').format('DD/MMMM/YYYY HH:mm:ss')}
                                                                                                        </h6>
                                                                                                        <h6>
                                                                                                            ยอดรวม:{' '}
                                                                                                            <NumberFormat
                                                                                                                value={parseFloat(
                                                                                                                    object.total_gross_price
                                                                                                                )}
                                                                                                                displayType={
                                                                                                                    'text'
                                                                                                                }
                                                                                                                decimalScale={
                                                                                                                    2
                                                                                                                }
                                                                                                                fixedDecimalScale={
                                                                                                                    true
                                                                                                                }
                                                                                                                thousandSeparator={
                                                                                                                    true
                                                                                                                }
                                                                                                                suffix={
                                                                                                                    ' บาท'
                                                                                                                }
                                                                                                            />
                                                                                                        </h6>
                                                                                                        <h6>
                                                                                                            การชำระเงิน:{' '}
                                                                                                            <span>
                                                                                                                <strong
                                                                                                                    className={`${object.status ===
                                                                                                                    'pending'
                                                                                                                        ? 'text-primary'
                                                                                                                        : 'text-success'}`}
                                                                                                                >
                                                                                                                    {object.status ===
                                                                                                                    'pending' ? (
                                                                                                                        'รอการชำระเงิน'
                                                                                                                    ) : (
                                                                                                                        'ชำระแล้ว'
                                                                                                                    )}
                                                                                                                </strong>
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-end'>
                                                                                                <NavLink
                                                                                                    to={`/order?oid=${object.id}`}
                                                                                                    target={'_blank'}
                                                                                                    className='btn btn-outline-primary'
                                                                                                    // onClick={() =>
                                                                                                    //     btnView(
                                                                                                    //         object.id
                                                                                                    //     )}
                                                                                                >
                                                                                                    ดูรายละเอียด
                                                                                                </NavLink>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    {arr.length - 1 !== index && <hr />}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </ul>
                                                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                                    <div className='d-flex flex-wrap py-2 mr-3'>
                                                                        {historys.info.length > initindex && (
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
                                                                                onClick={btnPrevious}
                                                                                disabled={endindex === initindex}
                                                                            >
                                                                                <i className='ki ki-bold-arrow-back icon-xs' />
                                                                            </button>
                                                                        )}
                                                                        {historys.info.length > initindex && (
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
                                                                                onClick={btnNext}
                                                                                disabled={
                                                                                    endindex >= historys.info.length
                                                                                }
                                                                            >
                                                                                <i className='ki ki-bold-arrow-next icon-xs' />
                                                                            </button>
                                                                        )}
                                                                        {/* <a href='#' className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'>
                                                <i className='ki ki-bold-double-arrow-next icon-xs' />
                                            </a> */}
                                                                    </div>
                                                                    <div className='d-flex align-items-center py-3'>
                                                                        <span className='text-muted'>
                                                                            แสดง{' '}
                                                                            {endindex >= historys.info.length ? historys.info.length : endindex}{' '}
                                                                            จาก {historys.info.length} รายการ
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
