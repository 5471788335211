import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { MemberDetail, MemberList } from './components';

import $ from 'jquery';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function MemberMain() {
    const { user } = useSelector((state) => state.auth);

    const history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ keyword, setKeyword ] = useState('');
    const [ init, setInit ] = useState(true);
    const [ disable, setDisable ] = useState(false);
    const [ memberlist, setMemberlist ] = useState({});
    const [ memberdetail, setMemberdetail ] = useState({});

    useEffect(() => {
        if (user.role_id !== '1') {
            history.push('/dashboard');
        }
    }, []);

    useEffect(() => {
        $(document).ready(function() {
            $('.view-info').click(function(e) {
                if (!disable) {
                    e.preventDefault();
                    btnView($(this).attr('data-memberid'));
                }
            });
        });
    });

    const btnSearch = () => {
        if (keyword === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรายละเอียดที่ต้องการค้นหาให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setMemberlist({});
        setLoading(true);
        setInit(false);
        fetch(config.url + 'admin/user/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                search: keyword,
                group_id: '2'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setMemberlist(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (mid) => {
        setMemberdetail({});
        setLoading(true);
        setDisable(true);
        fetch(config.url + 'admin/user/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: mid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisable(false);
                setMemberdetail(result);
                setPage('detail');
            })
            .catch((error) => {
                setLoading(false);
                setDisable(false);
            });
    };

    return (
        <div className='member-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <MemberList
                            init={init}
                            disable={disable}
                            keyword={keyword}
                            setKeyword={setKeyword}
                            memberlist={memberlist}
                            btnSearch={btnSearch}
                            btnView={btnView}
                        />
                    ) : page === 'detail' ? (
                        <MemberDetail setPage={setPage} memberdetail={memberdetail} user={user} />
                    ) : null}
                </div>
            )}
        </div>
    );
}
