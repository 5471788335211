import React, { useEffect } from 'react';

import $ from 'jquery';

export function MemberList(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.memberlist).length > 0) {
                    if (props.memberlist.responsecode === 0) {
                        $('#member-list-table').DataTable({
                            data: props.memberlist.info,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            order: [ [ 4, 'asc' ] ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            columns: [
                                {
                                    title: 'ดูรายละเอียด',
                                    className: 'dt-body-center',
                                    data: null,
                                    render: function(data, type, row) {
                                        return `<button
                                                    type="button"
                                                    class="btn btn-icon btn-primary rounded-lg view-info"
                                                    data-memberid="${data.id}"
                                                >
                                                    <i class="fa fa-search"></i>
                                                </button>`;
                                    }
                                },
                                {
                                    title: 'ชื่อสมาชิก',
                                    // data: "name",
                                    data: function(value) {
                                        if (
                                            value.first_name !== null &&
                                            value.first_name !== '' &&
                                            value.last_name !== null &&
                                            value.last_name !== ''
                                        ) {
                                            return value.first_name + ' ' + value.last_name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'เบอร์ปันสุข',
                                    data: function(value) {
                                        if (value.username !== null && value.username !== '') {
                                            return value.username;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'เบอร์ติดต่อกลับ',
                                    data: function(value) {
                                        if (value.contact_number !== null && value.contact_number !== '') {
                                            return value.contact_number;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่สมัคร',
                                    data: 'created_date'
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.memberlist ]
    );

    // useEffect(() => {
    //     $(document).ready(function() {
    //         if (!props.disable) {
    //             $('.view-info').click(function() {
    //                 props.btnView($(this).attr('data-memberid'));
    //             });
    //         }
    //     });
    // });

    return (
        <div className='row main-page'>
            <div className='col-12'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12 p-0'>
                                <div className='form-group mb-0'>
                                    <label>กรุณากรอกรายละเอียดที่ต้องการค้นหา</label>
                                    <div className='input-group'>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg rounded-right-0'
                                            value={props.keyword}
                                            onChange={(e) => props.setKeyword(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    props.btnSearch();
                                                }
                                            }}
                                        />
                                        <div className='input-group-append cursor-pointer' onClick={props.btnSearch}>
                                            <span className='input-group-text rounded-lg rounded-left-0'>
                                                <span className='fa fa-search' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!props.init && (
                <div className='col-12 my-5'>
                    <div className='card rounded-lg'>
                        <div className='card-body'>
                            {Object.keys(props.memberlist).length <= 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {props.memberlist.responsecode !== 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>
                                                    ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                    กรุณาลองใหม่อีกครั้ง
                                                </h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-12 p-0'>
                                                <table
                                                    id='member-list-table'
                                                    className='table table-striped table-bordered'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
