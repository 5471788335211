import React , { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../config';
import { ProductTable,ProductDetail } from './components/index';
import AddProductMain from './components/add';
import EditProductMain from './components/edit'
import { Modal,Nav, Tab } from 'react-bootstrap';

export function ProductMain(props) {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ key, setKey ] = useState('disable');
    const [ productlist, setProductlist ] = useState([]);
    const [ categoryFind, setCategoryFind ] = useState([]);
    const [ detailProduct, setDetailProduct ] = useState({});
    const [ product, setProduct ] = useState({});
    //const [categoryName, setCategoryName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [productView, setProductView] = useState(null);
    const handleClose = () => setShowModal(false);

    useEffect(()=>{
            let param = {
                token: user.token,
                filters: {'status' : key}
            };
        getProductList(param)
    },[key])

    const getProductList =(param)=>{
        setLoading(true);
        setProductlist([])
        fetch(config.url + 'admin/product/list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                    setLoading(false);
                    setProductlist(result)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    const getCategoryFind =()=>{
        setLoading(true);
        fetch(config.url + 'guest/category/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                filters: {}
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode == 0) {
                    setCategoryFind(result.catalogs)
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    const handleAddProduct = () => {
        getCategoryFind()
        setLoading(false);
        setPage('add')
        
    };
    const btnEdit = (productid)=>{
        setLoading(true);
        fetch(config.url + 'admin/product/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: productid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    getCategoryFind()
                    setProduct(result.info);
                    setPage('edit');
                } else {
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                return;
            });
    }
    const btnQuickView = (productid)=>{
        setShowModal(true);
        //setLoading(true);
        fetch(config.url + 'admin/product/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: productid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setProductView(result.info);
                } else {
                    return;
                }
            })
            .catch((error) => {
                //setLoading(false);
                return;
            });
    }
    const btnView = (productid) => {
        setLoading(true);
        fetch(config.url + 'admin/product/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: productid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setDetailProduct(result.info);
                    setPage('detail');
                } else {
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                return;
            });
    };
    const handleCreate =(category,name,description,unit,price,vat,quantity,fileimg)=>{
        fetch(config.url + 'admin/product/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                token : user.token,
                name : name,
                cat_id : category,
                description : description,
                unit : unit,
                price : price,
                vat : vat,
                qty : quantity,
                base64_img : [fileimg]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode == 0) {
                    setPage('main')
                    getProductList()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleUpdate =(id,category,name,description,unit,price,vat,quantity,fileimg)=>{
        console.log('update',id,category,name,description,unit,price,vat,quantity,fileimg)
        fetch(config.url + 'admin/product/update', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                token : user.token,
                id : id,
                update : 
                    { name : name,
                        cat_id : category,
                        description : description,
                        unit : unit,
                        price : price,
                        vat : vat,
                        qty : quantity,
                        base64_img : [fileimg]
                        }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode == 0) {
                    setPage('main')
                    getProductList()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleToggle =(ID)=>{
        setLoading(true);
        fetch(config.url + 'admin/product/toggle-status', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                token : user.token,
                id : ID
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode == 0) {
                    setPage('main')
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <div className="text-right">
                            {/* <NavLink className="menu-link" to="/"> */}
                                <button 
                                    type="button"
                                    onClick={handleAddProduct}
                                    className="btn btn-success btn">
                                        <i className="fa fa-plus text-white" />
                                        เพิ่มสินค้า
                                </button>
                            {/* </NavLink> */}
                        <br/>
                        <br/>
                        <div className={'card card-custom card-stretch gutter-b'}>
                            <div className='card-header border-0 pt-10'>
                                <h1 className='card-title align-items-start flex-column'>
                                    <span className='card-label font-weight-bolder text-dark'>สินค้า</span>
                                </h1>
                                <div className='card-toolbar'>
                                    <Tab.Container defaultActiveKey={key} onSelect={_eventKey => setKey(_eventKey)}>
                                        <div className='row'>
                                            <div className='col-12 p-0'>
                                                <Nav className='nav nav-pills nav-pills-sm nav-dark-75'>
                                                    <Nav.Item className='nav-item'>
                                                        <Nav.Link eventKey='disable'>รอดำเนินการ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='nav-item'>
                                                        <Nav.Link eventKey='enable'>อนุมัติ</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                            <ProductTable 
                                productlist={productlist} 
                                btnView={btnView} 
                                btnEdit={btnEdit} 
                                btnQuickView={btnQuickView}
                                />
                        </div>
                    </div>
                    ) : page === 'add' ? (
                        <AddProductMain
                            setPage={setPage}
                            handleCreate={handleCreate}                            
                            categoryFind={categoryFind}
                            // btnApproved={btnApproved}
                        />
                    ): page === 'detail' ? (
                        <ProductDetail
                            setPage={setPage}
                            handleToggle={handleToggle}
                             detailProduct={detailProduct}
                            setDetailProduct={setDetailProduct}
                            // btnApproved={btnApproved}
                        />
                    ) : page === 'edit' ? (
                        <EditProductMain
                            setPage={setPage}
                            handleToggle={handleToggle}
                             product={product}
                            setDetailProduct={setDetailProduct}
                            handleUpdate={handleUpdate}
                            categoryFind={categoryFind}
                            // btnApproved={btnApproved}
                        />
                        ):null}
                </div>
            )}
            <Modal show={showModal} onHide={handleClose} centered size='lg' aria-labelledby='product-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='product-modal' className='col-11 p-0'>
                        ดูสินค้าแบบรวดเร็ว
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {/* <center>
                        <h1>ดูสินค้าแบบรวดเร็ว</h1>
                    </center> */}
                    {productView !== null ?(
                        <div className="row">
                            <div className="col-6">
                                <img width='300'src={'data:image/png;base64, ' +productView.gallery.cover_image}></img>
                                {/* <img src={`data:image/jpeg;base64,${productView.gallery.cover_image}`}></img> */}
                            </div>
                            <br/>
                            <div className="col-6">
                                {/* <div style={{fontSize : '20px'}}> */}
                                <strong style={{fontSize : '20px'}}>ชื่อสินค้า :</strong>&nbsp; &nbsp;
                                <span style={{fontSize : '18px'}}>{productView.name}</span>
                                <br/>
                                <strong style={{fontSize : '20px'}}>รายละเอียดสินค้า :</strong>&nbsp;&nbsp;
                                <span style={{fontSize : '18px'}}>{productView.description}</span>
                                <br/>
                                <strong style={{fontSize : '20px'}}>ราคา :</strong>&nbsp;&nbsp;
                                <span style={{fontSize : '18px'}}>{productView.price}</span>
                                <br/>
                                <strong style={{fontSize : '20px'}}>vat 7% :</strong>&nbsp;&nbsp;
                                <span style={{fontSize : '18px'}}>{productView.vat}</span>
                                <br/>
                                <strong style={{fontSize : '20px'}}>จำนวนสินค้า :</strong>&nbsp;&nbsp;
                                <span style={{fontSize : '18px'}}>{productView.qty}</span>
                                {/* </div> */}
                            </div>
                    </div>
                    ): ''}
                </Modal.Body>
            </Modal>
        </div>
    )
}