import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../../../../modules/Auth/_redux/authRedux';
import { result } from 'lodash';
// import config from '../../../../config';

function EditProductMain(props) {
    console.log('props',props.product)
    console.log('props.category',props.categoryFind , typeof props.categoryFind)

    const imgRef = React.createRef()
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [fileimg, setFileImg] = useState();
    const [category, setCategory] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [unit, setUnit] = useState();
    const [price, setPrice] = useState();
    const [vat, setVat] = useState();
    const [quantity, setQuantity] = useState();
    const [productItems, setProductItems] = useState(props.product);
    const [categoryName, setCategoryName] = useState('');

    //const [id, setID] = useState();

    // const [base64img ,setBase64Img]= useState();
    const btnBack = () => {
        props.setPage('main');
        props.setDetailProduct({})
    };
    const onFileChange = event => {
        // Update the state
        setFileImg(event.target.files[0] );
      };

    const  getBase64=(event)=> {
        const File =(event.target.files[0] )
        const reader = new FileReader();
        reader.readAsDataURL(File);
        reader.onload = () => {
            setFileImg(reader.result)
        };
    }
    useEffect(()=>{
        setCategory(props.product.cat_title)
        setName(props.product.name)
        setDescription(props.product.description)
        setUnit(props.product.unit)
        setPrice(props.product.price)
        setVat(props.product.vat)
        setQuantity(props.product.qty)
        console.log('img',props.product.gallery.cover_image)
        setFileImg(props.product.gallery.cover_image)

        // props.categoryFind.filter((cat)=> cat.id === parseInt(props.product.cat_id)).map((category) => {
        //     console.log(category)
        //     setCategoryName(category.title)
        // })
        // console.log('type' , typeof productItems.cat_id,productItems.cat_id )
        // props.categoryFind.filter((cat)=> console.log('cat', cat))

            // cat.id=== parseInt(productItems.cat_id)}).map((category_name)=> {
            // console.log('categoryName', categoryName)
            // setCategoryName(category_name)})

    },[])
    const handleUpdate=(e)=> {
        props.handleUpdate(productItems.id,category,name,description,unit,price,vat,quantity,fileimg);
    }
    
    const categoryChange=(e)=> {
        setCategory(e.target.value );
    }
    const nameChange=(e)=> {
        setName(e.target.value );
    }
    const descriptionChange=(e)=> {
        setDescription( e.target.value );
    }
    const unitChange=(e)=> {
        setUnit(e.target.value);
    }
    const priceChange=(e)=> {
        setPrice(e.target.value );
        setVat(parseFloat(e.target.value*0.07).toFixed(2))
    }
    const vatChange=(e)=> {
        setVat(e.target.value);
    }
    const qtyChange=(e)=> {
        setQuantity(e.target.value);
    }
    
    return (
        <div className='setting-change-password-page'>
            <div className='row justify-content-center align-content-center min-h-500px'>
                <div className='col-lg-8 col-12 p-md-20 pt-10 bg-white'>
                <div className='p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center p-0'>
                            <h6 className='text-dark font-weight-bold mb-10'>แก้ไขสินค้า</h6>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>หมวดหมู่</label>
                        <div className='col-10'>
                            <select className='form-control form-control-lg rounded-lg'
                            // defaultValue={categoryName}
                            // defaultValue="เสื้อ"
                            onChange={categoryChange}//setCategory(value.key)
                            >
                                <option value='' disabled selected>{category}</option>
                                {props.categoryFind.map((item) => {
                                    
                                        return(
                                        <option  key={item.id} value={item.title}>{item.title}</option>
                                        )
                                })}
                            </select>
                        </div>
                        <h3>{categoryName}</h3>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>ชื่อสินค้า</label>
                        <div className='col-10'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                defaultValue={name}
                                onChange={nameChange}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>รายละเอียด</label>
                        <div className='col-10'>
                            <textarea 
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                defaultValue={description}
                                onChange={descriptionChange}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>หน่วยสินค้า</label>
                        <div className='col-10'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                defaultValue={unit}
                                onChange={unitChange}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>ราคา</label>
                        <div className='col-10'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                defaultValue={price}
                                onChange={priceChange}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>vat7%</label>
                        <div className='col-10'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                value={vat}
                                onChange={vatChange}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>จำนวน</label>
                        <div className='col-10'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                defaultValue={quantity}
                                onChange={qtyChange}
                             />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-2 text-lg-right text-left'>ภาพสินค้า</label>
                        <div className='col-10'>
                            <input type="file" 
                            defaultValue={fileimg}
                            onChange={getBase64} 
                            />
                        </div>
                        {/* <div className='col-10'>
                        <img ref={imgRef} src={'data:image/jpg;base64,' + productItems.gallery.cover_image} width='150' height='150'></img>
                        </div> */}
                    </div>
                    <div className='row justify-content-center'>
                        <button type='button' className='btn btn-primary rounded-lg' 
                        onClick={handleUpdate}
                        >
                            ยืนยัน
                        </button>
                    </div>
                </div>
            </div>
    </div>
    );
}

export default injectIntl(connect(null, auth.actions)(EditProductMain));
