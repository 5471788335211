import React ,{useState}from 'react';

export function ProductDetail(props) {
    const imgRef = React.createRef()
    const btnBack = () => {
        props.setPage('main');
        props.setDetailProduct({})
    };

    const handleToggle = () => {
        props.handleToggle(props.detailProduct.id)
    };

    return (
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <div className='p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card rounded-lg'>
                    <div className='card-body rounded-lg rounded-top-0'>
                        <h3 className='d-inline-block mb-5'>รายละเอียดสินค้า</h3>
                        <div className='row justify-content-between m-0'>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รูปภาพสินค้า : </h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <div className='row'>
                                    <img className='col-6' ref={imgRef} src={`data:image/jpeg;base64,${props.detailProduct.gallery.cover_image}`}/>
                                </div>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> ชื่อสินค้า : {props.detailProduct.name}</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รายละเอียดสินค้า : {props.detailProduct.description}</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> ราคา/ชิ้น : {parseFloat(props.detailProduct.price)} บาท</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> ภาษีมูลค่าเพิ่ม : {parseFloat(props.detailProduct.vat)} บาท</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> หน่วย : {props.detailProduct.unit}</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> จำนวน : {parseFloat(props.detailProduct.qty)} ชิ้น</h6>
                            </div>
                        </div>
                        {props.detailProduct.status === 'disable' ? (
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button type='button' className='btn btn-success rounded-lg' 
                                onClick={handleToggle}>
                                    <i className='far fa-check-circle' /> แสดงสินค้า
                                </button>
                            </div>
                        ) :  (
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button type='button' className='btn btn-danger rounded-lg' 
                                onClick={handleToggle}>
                                    <i className='far fa-check-circle' /> ซ่อนสินค้า
                                </button>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}