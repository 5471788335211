import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import { useDropzone } from 'react-dropzone';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import Moment from 'moment';

import { OrderList, OrderDetail } from './components';

import $ from 'jquery';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function OrderMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ init, setInit ] = useState(true);
    const [ oid, setOid ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });
    const [ pdate, setPDate ] = useState({
        from: null,
        to: null
    });
    const [ orderlist, setOrderlist ] = useState({});
    const [ orderdetail, setOrderdetail ] = useState({});
    const [ importfile, setImport ] = useState('');
    const [ qrcode, setQrcode ] = useState('');
    const [ ref1, setRef1 ] = useState('');
    const [ ref2, setRef2 ] = useState('');

    const [ showModal, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        let queryparam = getQueryVariable('oid');
        if (queryparam) {
            btnView(queryparam);
        }
    }, []);

    useEffect(() => {
        $(document).ready(function() {
            $('.view-info').click(function(e) {
                e.preventDefault();
                btnView($(this).attr('data-orderid'));
            });
        });
    });

    const btnSearch = () => {
        let param = {
            token: user.token
        };
        let filters = {};
        if (oid !== '') {
            filters['id'] = oid;
        }
        if (status !== '') {
            filters['status'] = status.value;
        }
        if (date.from !== null && date.to !== null) {
            filters['order_date'] = {
                start: date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2),
                end: date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2)
            };
        }
        if (pdate.from !== null && pdate.to !== null) {
            filters['paid_date'] = {
                start:
                    pdate.from.year + '-' + ('0' + pdate.from.month).slice(-2) + '-' + ('0' + pdate.from.day).slice(-2),
                end: pdate.to.year + '-' + ('0' + pdate.to.month).slice(-2) + '-' + ('0' + pdate.to.day).slice(-2)
            };
        }
        if (Object.keys(filters).length > 0) {
            param['filters'] = filters;
        }
        setOrderlist({});
        setLoading(true);
        setInit(false);
        fetch(config.url + 'admin/order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderlist(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (oid) => {
        setLoading(true);
        fetch(config.url + 'admin/order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: oid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setOrderdetail(result);
                    setQrcode(result.info.qr);
                    setRef1(result.info.reference1);
                    setRef2(result.info.reference2);
                    setPage('view');
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnRenew = (oid) => {
        setLoading(true);
        fetch(config.url + 'admin/order/regenerate-qr', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: oid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((event) => {
                        if (event.isConfirmed) {
                            setQrcode(result.qr);
                            setRef1(result.reference1);
                            setRef2(result.reference2);
                        }
                    });
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnCancel = (oid) => {
        setLoading(true);
        fetch(config.url + 'admin/order/cancel', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: oid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnDownload = (kerry = false) => {
        let filename = '';
        let param = {
            token: user.token
        };
        let filters = {};
        if (kerry) {
            filename = 'KerryExpressImportTemplate';
            param['output'] = 'excel_kerry';
            filters['status'] = 'paid';
        } else {
            filename = 'Shipment';
            param['output'] = 'excel';
            // if (status !== '') {
            filters['status'] = 'paid';
            // }
        }
        if (oid !== '') {
            filters['id'] = oid;
        }
        if (date.from !== null && date.to !== null) {
            filters['order_date'] = {
                start: date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2),
                end: date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2)
            };
        }
        if (pdate.from !== null && pdate.to !== null) {
            filters['paid_date'] = {
                start:
                    pdate.from.year + '-' + ('0' + pdate.from.month).slice(-2) + '-' + ('0' + pdate.from.day).slice(-2),
                end: pdate.to.year + '-' + ('0' + pdate.to.month).slice(-2) + '-' + ('0' + pdate.to.day).slice(-2)
            };
        }
        if (Object.keys(filters).length > 0) {
            param['filters'] = filters;
        }
        setLoading(true);
        fetch(config.url + 'admin/order/shipping/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.setAttribute('type', 'hidden');
                    link.href = 'data:text/plain;base64,' + result.base64;
                    link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.xlsx`;
                    link.click();
                    document.body.removeChild(link);
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnUpload = () => {
        if (importfile === '') {
            SwalBs.fire({
                html: 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setLoading(true);
        fetch(config.url + 'admin/reports/shipping/import', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                base64: importfile
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const binaryStr = reader.result.split(',').pop();
                setImport(binaryStr);
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const files = acceptedFiles.map((file, index) => {
        return (
            <div className='dz-preview dz-file-preview dz-processing dz-error dz-complete' key={index}>
                <div className='dz-image'>
                    {/* {file.path.includes('.xlsx') ? (
                        <i className='far fa-file-excel'/>
                    ) : (
                        <i className='far fa-file-alt' />
                    )} */}
                    {/* <img data-dz-thumbnail alt="thumbnail"/> */}
                </div>
                <div className='dz-details'>
                    <div className='dz-size'>
                        <span data-dz-size>
                            <strong>{formatBytes(file.size).split(' ')[0]}</strong>{' '}
                            {formatBytes(file.size).split(' ')[1]}
                        </span>
                    </div>
                    <div className='dz-filename'>
                        <span data-dz-name>{file.path}</span>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <div className='order-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <OrderList
                            init={init}
                            oid={oid}
                            setOid={setOid}
                            status={status}
                            setStatus={setStatus}
                            date={date}
                            setDate={setDate}
                            pdate={pdate}
                            setPDate={setPDate}
                            orderlist={orderlist}
                            btnSearch={btnSearch}
                            btnDownload={btnDownload}
                            handleShow={handleShow}
                        />
                    ) : page === 'view' ? (
                        <OrderDetail
                            setPage={setPage}
                            getQueryVariable={getQueryVariable}
                            orderdetail={orderdetail}
                            qrcode={qrcode}
                            ref1={ref1}
                            ref2={ref2}
                            btnRenew={btnRenew}
                            btnCancel={btnCancel}
                        />
                    ) : null}
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='xl' centered aria-labelledby='unactive-number-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        เพิ่มข้อมูลหมายเลขพัสดุ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {/* <div className="col-sm-12 d-flex justify-content-between py-5">
                            <span className='my-auto mr-15 font-weight-bolder font-size-lg'>ดาวน์โหลดตัวอย่างไฟล์ : </span>
                            <span className='text-right'>
                                <button type="button" className="btn btn-primary rounded-lg" onClick={() => downloadExcelFile(prop.response.base64)}>
                                    <i className="fas fa-file-excel"></i> ดาวน์โหลด Excel
                                </button>
                            </span>
                        </div> */}
                        <div className='col-sm-12'>
                            <div
                                {...getRootProps({
                                    className: 'dropzone dropzone-default dz-clickable cus-dropzone bg-white'
                                })}
                            >
                                <input {...getInputProps()} />
                                {acceptedFiles.length === 0 ? (
                                    <div>
                                        <div className='uppy-DragDrop-inner'>
                                            <svg
                                                aria-hidden='true'
                                                focusable='false'
                                                className='uppy-c-icon uppy-DragDrop-arrow'
                                                width={16}
                                                height={16}
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M11 10V0H5v10H2l6 6 6-6h-3zm0 0' fillRule='evenodd' />
                                            </svg>
                                            <div className='uppy-DragDrop-label'>
                                                วางไฟล์ที่นี่หรือ<span className='uppy-DragDrop-browse'>เรียกดู</span>
                                            </div>
                                            <span className='uppy-DragDrop-note' />
                                        </div>
                                    </div>
                                ) : (
                                    <div>{files}</div>
                                )}
                            </div>
                        </div>
                        <div className='col-sm-12 d-flex justify-content-end pt-5'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={btnUpload}>
                                <i className='fas fa-file-upload' /> อัพโหลด
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
