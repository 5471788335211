import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
import { DashboardPage } from './pages/DashboardPage';

import { MemberMain } from './pages/customs/member/main';
import { ProductMain } from './pages/customs/product/main';

import { OrderMain } from './pages/customs/order/main';

import SettingPasswordMain from './pages/customs/setting/password/main';

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/dashboard' />
                }
                <ContentRoute path='/dashboard' component={DashboardPage} />
                <ContentRoute path='/member' component={MemberMain} />
                <ContentRoute path='/order' component={OrderMain} />
                <ContentRoute path='/product' component={ProductMain}/>
                <ContentRoute path='/setting/password' component={SettingPasswordMain} />
                {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
