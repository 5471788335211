import React, { useEffect ,useState} from 'react';
import $, { data } from 'jquery';

export function ProductTable(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.productlist).length > 0) {
                    if (props.productlist.responsecode === 0) {
                        $('#product-list-table').DataTable({
                            data: props.productlist.products,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            order: false,
                            columns: [
                                {
                                    title: 'ดูรายละเอียด',
                                    className: 'dt-body-center',
                                    data: null, // 'btn btn-icon btn-primary view-shop-info'
                                    width : '80px',
                                    render: function(data, type, row) {
                                        return `<button
                                                    type='button'
                                                    class='btn btn-icon btn-primary view-shop-info'
                                                    data-id=${data.id}
                                                    >
                                                        <i class='fa fa-search'></i>
                                                </button>
                                                <button
                                                    type='button'
                                                    class='btn btn-icon btn-info modify-product'
                                                    data-id=${data.id}
                                                    >
                                                    <i class="fa fa-cog"></i>
                                                </button>
                                                <button
                                                    type='button'
                                                    class='btn btn-icon btn-warning view-product-info'
                                                    data-id=${data.id}
                                                    >
                                                    <i class="fa fa-eye"></i>
                                                </button>`;
                                    }
                                },
                                {
                                    title: 'ชื่อสินค้า',
                                    className: 'dt-body-center',
                                    width : '200px',
                                    data: function(value) {
                                        if (value.name !== null && value.name !== '') {
                                            return value.name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ประเภทสินค้า',
                                    className: 'dt-body-center',
                                    width : '200px',
                                    data: function(value) {
                                        if (value.category_title !== null && value.category_title !== '') {
                                            return value.category_title;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'สถานะสินค้า',
                                    className: 'dt-body-center',
                                    width : '100px',
                                    data: function(value) {
                                        if (value.status === 'disable') {
                                            return '<span class="label label-danger label-pill label-inline mx-auto my-auto">ปิดขาย</span>';
                                        } else if (value.status === 'enable') {
                                            return '<span class="label label-success label-pill label-inline mx-auto my-auto">เปิดขาย</span>';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.productlist ]
    );

    useEffect(() => {
        $(document).ready(function() {
            $('.view-shop-info').click(function() {
                props.btnView($(this).attr('data-id'));
            });
        });
        $(document).ready(function() {
            $('.modify-product').click(function() {
                props.btnEdit($(this).attr('data-id'));
            });
        });
        $(document).ready(function() {
            $('.view-product-info').click(function() {
                props.btnQuickView($(this).attr('data-id'));
            });
        });
    });
    

    return (
        <div className='col-12 my-5'>
            <div className='card rounded-lg'>
                <div className='card-body'>
                    {Object.keys(props.productlist).length <= 0 ? (
                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                            <div className='col-12 text-center'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {props.productlist.responsecode !== 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>
                                            ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                            กรุณาลองใหม่อีกครั้ง
                                        </h3>
                                    </div>
                                </div>
                            ) : (
                                <div className='row'>
                                    <div className='col-12 p-0'>
                                        <table
                                            id='product-list-table'
                                            className='table table-striped table-bordered'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
);
}